import React, { useEffect } from 'react';
import { navigate, graphql } from 'gatsby';

import siteData from '../../config/siteData';

const IndexPage = () => {
  useEffect(() => {
    void navigate(`/${siteData.fallbackLocale}/`, { replace: true });
  }, []);

  return null;
};

export default IndexPage;
